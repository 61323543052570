import React from "react";
import { Container } from "@atoms";
import { HubspotForm } from "@molecules";
// import AccordionPattern from "@svg/AccordionPattern";

const ContactForm = ({ heading, descriptor, formId }) => {
  return (
    <div className="relative my-10">
      <Container variant="contact" padding className="relative text-center">
        {/* <AccordionPattern className="absolute -right-64 -top-40 -z-20 w-[30rem] -rotate-90 -scale-x-100 text-purple opacity-0" /> */}
        <HubspotForm
          heading={heading}
          description={descriptor}
          formId={formId}
          rightButton
          fluid
          darkLabels
          newsletterCheckbox
        />
        {/* <AccordionPattern className="absolute -bottom-6 -left-[19rem] -z-20 w-[34rem] rotate-90 -scale-x-100 text-purple opacity-0" /> */}
      </Container>
    </div>
  );
};

ContactForm.defaultProps = {};

export default ContactForm;
